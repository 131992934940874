@import url('https://fonts.googleapis.com/css?family=Roboto:400,700&display=swap');
* {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
}

html,
body,
#root {
    min-height: 100%;
}

body {
    background: #000 url('./assets/background.jpg');
    background-size: cover;
    -webkit-font-smoothing: antialiased!important;
}

body,
input,
button {
    font-family: 'Roboto', Arial, Helvetica, sans-serif;
    font-size: 14px;
}

.container {
    margin: 80px auto 0;
    max-width: 450px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.content {
    width: 100%;
    background-color: #fff;
    margin-top: 50px;
    border-radius: 4px;
    padding: 20px;
}

.content>p {
    font-size: 22px;
    line-height: 30px;
    margin-bottom: 30px;
}

.content form {
    display: flex;
    flex-direction: column;
}

.content form label {
    font-size: 14px;
    color: #444;
    font-weight: bold;
    margin-bottom: 8px;
}

.content form label span {
    font-weight: normal;
    color: #999;
    font-size: 12px;
}

.content form input {
    margin-bottom: 20px;
    border: 1px solid #ddd;
    height: 45px;
    padding: 0 15px;
    font-size: 16px;
}

.content button.btn {
    border: 0;
    border-radius: 2px;
    width: 100%;
    height: 42px;
    padding: 0 20px;
    font-weight: bold;
    background-color: #f05a5b;
    color: #fff;
    cursor: pointer;
}

.content button.btn:hover {
    background-color: #e14f50;
}
