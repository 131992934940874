label#thumbnail {
    margin-bottom: 20px;
    border: 1px solid #ddd;
    cursor: pointer;
    background-size: cover;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center
}

label#thumbnail input {
    display: none;
}

label#thumbnail.has-thumbnail {
    border: none;
}

label#thumbnail.has-thumbnail img {
    display: none;
}